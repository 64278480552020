import { ChangeDetectionStrategy, Component, effect, input, model } from '@angular/core';

@Component({
  selector: 'app-chips-input',
  standalone: false,
  templateUrl: './chips-input.component.html',
  styleUrl: './chips-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChipsInputComponent {
  editable = input<boolean>(false);
  chips = model<string[]>([]);
}
